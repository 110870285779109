import axios from "axios";
import { useEffect, useState } from "react";
import TimTblStructure from "./TimTblStructure";

const AdminTimeTableView = ({ onTimeTblData }) => {
  const financialYear = localStorage.getItem("AY");
  const nToken = localStorage.getItem("nToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [classSec, setClassSec] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userInfo[0]?.institution}`;
        axios
          .get(baseURL, {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          })
          .then((response) => {
            setClassSec(response.data.class_section);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const fetchTimeTbl = async () => {
      try {
        let timeTblUrl = `${process.env.REACT_APP_nodewsPrefix}/viewTimeTable?class=${selectedClass}&section=${selectedSection}&financial_year=${financialYear}&schoolcode=${userInfo[0]?.institution}`;

        const response = await axios.get(timeTblUrl, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        onTimeTblData(response.data.timetable_data);
        setLoading(true);
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    fetchTimeTbl();
  }, [selectedClass, selectedSection]);

  const handleClassSelect = (data) => {
    setSelectedClass(data);
  };

  const handleSectionSelect = (data) => {
    setSelectedSection(data);
  };

  const handleSelectVal = (data) => {
    setSelectedClass(data);
  };

  return (
    <TimTblStructure
      classSec={classSec}
      onSelectClass={handleClassSelect}
      onSelectSection={handleSectionSelect}
      onSelectClassVal={handleSelectVal}
    />
  );
};

export default AdminTimeTableView;
