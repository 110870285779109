import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ListingCard from "../../_Components/Common/Cards/ListingCard/ListingCard";
import { Scrollbar } from "react-scrollbars-custom";
import "./AnnualPlanner.scss";
import CardSecondary from "../../_Components/Common/Cards/CardSecondary";

const ExamSheetList = ({ refreshEvent }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");
  const [upcomingEvent, setUpcomingEvent] = useState([]);

  useEffect(() => {
    const getUpcomingData = () => {
      axios
        .get(
          `${process.env.REACT_APP_nodewsPrefix}/viewExamEvent?usertype=${userInfo[0]?.department}&schoolcode=${userInfo[0]?.institution}`,
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        )
        .then((response) => {
          setUpcomingEvent(response.data?.msg);
        });
    };

    getUpcomingData();
  }, [refreshEvent]);

  const groupEventPlanner = (upcomingEventData) => {
    const groupEvent = [];
    upcomingEventData?.forEach((cert) => {
      const eventName = cert.name;
      console.log(eventName, cert, "eventName");
      if (!groupEvent[eventName]) {
        groupEvent[eventName] = [];
      }
      groupEvent[eventName].push(cert);
    });

    return groupEvent;
  };

  const upcomingEventInfo = groupEventPlanner(upcomingEvent);

  const getValueAfterLastDot = (str) => {
    const parts = str.split(".");
    return parts[parts.length - 1];
  };

  return (
    <CardSecondary curLanguage="Upcoming Exam" customClassName="examsheetCard">
      <Scrollbar style={{ width: "100%", height: `calc(53vh - 20px)` }}>
        {upcomingEventInfo ? (
          <>
            {Object.keys(upcomingEventInfo).map((examType, index) => (
              <Box key={index}>
                <Table aria-label="sticky table" sx={{ mb: 4 }}>
                  <TableHead>
                    <TableRow sx={{ background: "#ddd" }}>
                      <TableCell align="center" colSpan={3}>
                        {examType}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Class</TableCell>
                      <TableCell>Subject</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {console.log(
                      upcomingEventInfo[examType],
                      "fffffffffffffffff"
                    )}
                    {upcomingEventInfo[examType].map((item, itemIndex) => (
                      <TableRow key={itemIndex}>
                        <TableCell>{item.event_start}</TableCell>
                        <TableCell>
                          {getValueAfterLastDot(item.class_name).toUpperCase()}
                        </TableCell>
                        <TableCell>{item.subject_name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ))}
          </>
        ) : (
          <Box mt={1}>
            <ListingCard>No exam date has been scheduled.</ListingCard>
          </Box>
        )}
      </Scrollbar>
    </CardSecondary>
  );
};

export default ExamSheetList;
