import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { languages } from "../../App";
import axios from "axios";
import StudentRecord from "./StudentRecord";
import AddAttendance from "./AddAttendance";
import UploadAttendance from "./UploadAttendance";
import DownloadAllAttendance from "./DownloadAllAttendance";
import "./Attendance.scss";
import MainCard from "../../_Components/Common/Cards/MainCard";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";

const Attendance = () => {
  let curLanguage = useContext(languages);
  const token = localStorage.getItem("token");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYr = localStorage.getItem("AY");
  const [attendanceData, setAttendanceData] = useState(null);
  const [studentInfo, setStudentInfo] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const [classTeacher, setClassTeacher] = useState([]);
  const [attendanceLabel, setAttendanceLabel] = useState([]);
  const [selectedMonthVal, setSelectedMonthVal] = useState(new Date().getMonth() + 1);

  
  const handleAttendance = async (data) => {
    try {
      const studentIds = studentInfo.map((data) => data.id);
      const attendanceUrl = `${process.env.REACT_APP_nodewsPrefix}/viewAttendance`;
  
    
      setTimeout(async () => {
        const response = await axios.post(
          attendanceUrl,
          {
            student_id: studentIds,
            class_id: "",
            section_id: "",
            financial_year: financialYr,
            day: "",
            month: "",
            schoolcode: userinfo[0].institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
  
        const updatedAttendanceData = studentIds.reduce((content, studentId) => {
          content[studentId] = response.data.attendance_data[studentId];
          return content;
        }, {});
  
        setAttendanceData(updatedAttendanceData);
      }, 2000); 
    } catch (error) {
      console.error("Error adding attendance:", error);
    }
  };
  
  useEffect(() => {
    let studentsData = null;
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userinfo[0]?.id}&schoolcode=${userinfo[0]?.institution}`;
        const response1 = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        const classTeacherData = response1.data.rows.filter(
          (val) => val.is_classteacher === 1
        );
        setClassTeacher(classTeacherData);
        console.log(classTeacherData, "classTeacherData")
        const enroledURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${classTeacherData[0].courseid}&schoolcode=${Number(userinfo[0]?.institution)}&class=${classTeacherData[0].class}&section=${classTeacherData[0].section}`;
       
        const response2 = await axios.get(enroledURL);
        const studentDataArray = Object.values(response2.data);
        console.log(studentDataArray, "enroledURL")
        const studentData = studentDataArray.filter(
          (val) =>
            val.department.toLowerCase() === "student"
        );
        setStudentInfo(studentData);
        studentsData = studentData;

        const studentIds = studentsData?.map((data) => data.id);
        const attendanceUrl = `${process.env.REACT_APP_nodewsPrefix}/viewAttendance`;
        const response = await axios.post(
          attendanceUrl,
          {
            student_id: studentIds,
            class_id: "",
            section_id: "",
            financial_year: financialYr,
            day: "",
            month: "",
            schoolcode: userinfo[0].institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        const updatedAttendanceData = studentIds.reduce(
          (content, studentId) => {
            content[studentId] = response.data.attendance_data[studentId];
            return content;
          },
          {}
        );
        setAttendanceData(updatedAttendanceData);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    fetchData();
    const fetchAttendanceData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept`,
          {
            schoolcode: userinfo[0]?.institution,
            department: userinfo[0]?.department,
          }
        );
        setAttendanceLabel(response.data.config.attendance ? response.data.config.attendance : response.data.defaultConfig.attendance);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchAttendanceData();
  }, []);

  const selectedMonthData = (data) => {
    setSelectedMonthVal(data)
  }

  const dataPres = studentInfo.length > 0;
  return (
    <MainCard
      cardTitle={curLanguage.attendance}
      mainPage
      additionalData={
        <>
          {dataPres && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
              gap={1}
              className="additional-card-head"
            >
              <AddAttendance
                studentInfo={studentInfo}
                attendanceinfo={attendanceData && attendanceData}
                onAttendanceUpdate={handleAttendance}
                attendanceLabel={attendanceLabel}
              />
              <DownloadAllAttendance
                studentInfo={studentInfo}
                attendanceinfo={attendanceData}
                selectedMonthVal={selectedMonthVal}
              />
              <UploadAttendance onAttendanceUpdate={handleAttendance} />
            </Box>
          )}
        </>
      }
    >
      {classTeacher.length === 0 ? (
         <Box display={"flex"} justifyContent="center">
         <Typography
           variant="h6"
           mt={1}
           className="mssgCard"
         >
           As a subject teacher, you do not have permission to mark attendance.
         </Typography>
       </Box>
      ) : (
        <Box>
          {dataPres ? (
            <StudentRecord
              studentInfo={studentInfo}
              attendanceData={attendanceData}
              attendanceLabel={attendanceLabel}
              selectedMonth={selectedMonthData}
            />
          ) : (
            <CircularLoader />
          )}
        </Box>
      )}
    </MainCard>
  );
};

export default Attendance;
