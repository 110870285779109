import React, { useEffect, useState } from 'react';
import {
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox, FormGroup, FormControlLabel, FormLabel
} from '@mui/material';
import axios from 'axios';
import MainCard from '../Cards/MainCard';
import { PrimaryBgBtn } from '../Buttons/CustomBtns';
import { config } from '@fortawesome/fontawesome-svg-core';
function ViewModuleTable(props) {
    console.log(props);
    const [data, setData] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [selectedData, setSelectedData] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [editColumnName, setEditColumnName] = useState([]);
    const [permissions, setPermissions] = useState();
    const [newSection, setNewSection] = useState({
        role: "",
        department: "",
        section: "",
        additionalInfo: "",
        read: false,
        write: false,
        canAccess: false,
    });
    const [permissionss, setPermissionss] = useState({
        read: false,
        write: false,
        canAccess: false,
    });
    const [open, setOpen] = useState(false);
    const handleChange = (event) => {
        const title = event.target.value;
        setSelectedTitle(title);
        const selected = data.find((item) => item.title === title);
        setSelectedData(selected);
        
    };
    const nToken = localStorage.getItem("nToken");
    const getuserInfo = localStorage.getItem("userInfo");
    const getfinancialYear = localStorage.getItem("financialYear");
    const financialYear = JSON.parse(getfinancialYear);
    const userInfo = JSON.parse(getuserInfo);
    const schoolcode = userInfo[0].institution;
    const department = userInfo[0].department;
    useEffect(() => {
        const url = `${process.env.REACT_APP_nodewsPrefix}/viewDynamicTable?schoolcode=${schoolcode}`;
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${nToken}`,
            },
        }).then((response) => {
            setData(response.data.module_data);
        });
    }, [schoolcode, nToken,selectedTitle]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept`,
                    {
                        department: department,
                        schoolcode: schoolcode,
                    }
                );
                setPermissions(response.data.config);
            } catch (error) {
                console.error("Error fetching permissions:", error);
            }
        };

        fetchData();
    }, [schoolcode])
   
    
    const handleEditOpen = () => {
        console.log(selectedData,"rajjiCheck");
        props.editData({ ...selectedData, "edit": 1 })
        setSelectedTitle('');
        setSelectedData(null);
    };
    const handleDeletePopup = () => {
        setEditOpen(true)
    }
    const handleDelete = () => {
        if (!selectedTitle) return;
        const url = `${process.env.REACT_APP_nodewsPrefix}/dropTableDynamic?module=${selectedTitle}&schoolcode=${schoolcode}`;
        axios.delete(url, {
            headers: {
                Authorization: `Bearer ${nToken}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    const updatedData = data.filter((item) => item.title !== selectedTitle);
                    setData(updatedData);
                    setSelectedTitle('');
                    setSelectedData(null);
                }
            })
            .catch((error) => {
                console.error("Error deleting the data: ", error);
            });
    };

    // const handleAddSection = async () => {
    //     setPermissions(async (prevPermissions) => {
    //         const { role, department, section, } = newSection;
    //         const newPermissions = {
    //             [department]: {
    //                 [section]: {
    //                     ...([department]?.[section] || {}),
    //                     read: permissionss.read,
    //                     write: permissionss.write,
    //                     // canAccess: permissionss.canAccess,
    //                     type: selectedTitle
    //                 },
    //             },
    //         };

    //         var datas = null;
    //         if (permissions == null) {
    //             console.log("ind 1");
    //             const custommoduleData = { custommodules: newPermissions }
    //             datas = { ...custommoduleData };
    //         } else {
    //             console.log("ind 2");

    //             if ("custommodules" in permissions) {
    //                 let newObj = prevPermissions.custommodules
    //                 let newObj1 = {...newObj,...newPermissions}




    //                 datas = prevPermissions = { ...prevPermissions, custommodules:newObj1 }

    //                 // const valuesPresent = { prevPermissions: newPermissions }
    //                 // const vals = valuesPresent 
    //                 console.log(" custom module present pp0 ", datas);
    //             } else {
    //                 const custommoduleData = { custommodules: newPermissions }
    //                 datas = { ...prevPermissions, ...custommoduleData };
    //             }
    //         }



    //         try {
    //             await axios.post(`${process.env.REACT_APP_nodewsPrefix}/updateConfigData`,
    //                 {
    //                     config: JSON.stringify(datas),
    //                     schoolcode: schoolcode,

    //                 },
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${nToken}`,
    //                     }
    //                 }
    //             );
    //         } catch (error) {
    //             console.error("Error updating permissions:", error);
    //         }
    //         return newPermissions;
    //     });
    //     setOpen(false);
    //     setNewSection({
    //         role: "",
    //         department: "",
    //         section: "",
    //         additionalInfo: "",
    //         read: false,
    //         write: false,
    //         canAccess: false,
    //     });
    //     setPermissionss({
    //         read: false,
    //         write: false,
    //         canAccess: false,
    //     });
    //     setPermissions()
    // };
    // const handleAddSection = async () => {
    //     setPermissions(async (prevPermissions) => {
    //         const { department, section } = newSection;

    //         // Check if selectedTitle is valid (not empty or undefined)
    //         if (!selectedTitle) {
    //             console.error("Selected title is not defined.");
    //             return; // Early exit if no title is selected
    //         }

    //         // Create newPermissions with selectedTitle at the custommodules root level
    //         const newPermissions = {
    //             [selectedTitle]: {
    //                 [department]: {
    //                     [section]: {
    //                         read: permissionss.read,
    //                         write: permissionss.write,
    //                         type: selectedTitle,
    //                     }
    //                 }
    //             }
    //         };

    //         let datas = null;

    //         if (!prevPermissions || !prevPermissions.custommodules) {
    //             // If no previous permissions, initialize custommodules with the selectedTitle
    //             console.log("No previous permissions, initializing new structure.");
    //             datas = { custommodules: newPermissions };
    //         } else {
    //             // Merge the new object into the existing custommodules
    //             console.log("Merging with existing custommodules.");
    //             datas = {
    //                 ...prevPermissions,
    //                 custommodules: {
    //                     ...prevPermissions.custommodules,
    //                     [selectedTitle]: {
    //                         ...prevPermissions.custommodules[selectedTitle],
    //                         [department]: {
    //                             ...prevPermissions.custommodules[selectedTitle]?.[department],
    //                             [section]: {
    //                                 read: permissionss.read,
    //                                 write: permissionss.write,
    //                                 type: selectedTitle,
    //                             }
    //                         }
    //                     }
    //                 }
    //             };
    //         }

    //         // Print the final object before saving
    //         console.log("Object to save:", JSON.stringify(datas, null, 2));

    //         try {
    //             await axios.post(`${process.env.REACT_APP_nodewsPrefix}/updateConfigData`, {
    //                 config: JSON.stringify(datas),
    //                 schoolcode: schoolcode,
    //             }, {
    //                 headers: {
    //                     Authorization: `Bearer ${nToken}`,
    //                 }
    //             });
    //         } catch (error) {
    //             console.error("Error updating permissions:", error);
    //         }

    //         // Return the updated permissions object
    //         return datas;
    //     });

    //     // Reset the form and state after saving
    //     setOpen(false);
    //     setNewSection({
    //         role: "",
    //         department: "",
    //         section: "",
    //         additionalInfo: "",
    //         read: false,
    //         write: false,
    //         canAccess: false,
    //     });
    //     setPermissionss({
    //         read: false,
    //         write: false,
    //         canAccess: false,
    //     });
    //     setPermissions(null);  // Reset permissions
    // };
    const handleAddSection = async () => {
        setPermissions(async (prevPermissions) => {
            const { department, section } = newSection;

            // Create newPermissions based on department and section without using selectedTitle
            const newPermissions = {
                [department]: {
                    [section]: {
                        read: permissionss.read,
                        write: permissionss.write,
                        type: section, // Set type to section name for distinction
                    }
                }
            };

            let datas = null;

            if (!prevPermissions || !prevPermissions.custommodules) {
                // If no previous permissions, initialize custommodules
                console.log("No previous permissions, initializing new structure.");
                datas = { custommodules: newPermissions };
            } else {
                // Merge the new object into the existing custommodules
                console.log("Merging with existing custommodules.");
                datas = {
                    ...prevPermissions,
                    custommodules: {
                        ...prevPermissions.custommodules,
                        [department]: {
                            ...prevPermissions.custommodules[department],
                            [section]: {
                                read: permissionss.read,
                                write: permissionss.write,
                                type: section, // Set type to section name
                            }
                        }
                    }
                };
            }

            // Print the final object before saving
            console.log("Object to save:", JSON.stringify(datas, null, 2));

            try {
                await axios.post(`${process.env.REACT_APP_nodewsPrefix}/updateConfigData`, {
                    config: JSON.stringify(datas),
                    schoolcode: schoolcode,
                }, {
                    headers: {
                        Authorization: `Bearer ${nToken}`,
                    }
                });
            } catch (error) {
                console.error("Error updating permissions:", error);
            }

            // Return the updated permissions object
            return datas;
        });

        // Reset the form and state after saving
        setOpen(false);
        setNewSection({
            role: "",
            department: "",
            section: "",
            additionalInfo: "",
            read: false,
            write: false,
            canAccess: false,
        });
        setPermissionss({
            read: false,
            write: false,
            canAccess: false,
        });
        setPermissions(null);  // Reset permissions
    };



    const handleChanges = (event) => {

        setPermissionss({
            ...permissionss,
            [event.target.name]: event.target.checked,
        });


    };

    return (
        <MainCard cardTitle="" customClass="" mt={2}>
            <Box sx={{ p: 3 }}>
                <FormControl fullWidth>
                    <InputLabel id="select-label">Select Module</InputLabel>
                    <Select
                        labelId="select-label"
                        value={selectedTitle}
                        onChange={handleChange}
                        label="Select Title"
                    >
                        {data.map((item) => (
                            <MenuItem key={item.title} value={item.title}>
                                {item.title.replaceAll("_", " ")}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedData && (
                    <>
                        <Typography variant="h6" sx={{ mt: 3 }}>
                            {selectedData.title.replaceAll("_", " ")}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            {selectedData.description}
                        </Typography>
                        <PrimaryBgBtn onClick={handleEditOpen} sx={{ mb: 2 }}>
                            Edit
                        </PrimaryBgBtn>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleDeletePopup}
                            sx={{ mb: 2, ml: 2 }}
                        >
                            Delete
                        </Button>
                        <PrimaryBgBtn onClick={() => setOpen(true)} sx={{ ml: 5, mb: 2 }}>
                            Set Permission
                        </PrimaryBgBtn>
                        { console.log(selectedData)}
                        <TableContainer component={Paper} sx={{ mt: 3 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {selectedData.customColumn.map((col) => (
                                            <TableCell key={col.id}>{col.section_data}</TableCell>    
                                        ))}
                                        {selectedData.columnName.map((col, index) => (
                                            <TableCell key={index}>{col.replaceAll('_',' ')}</TableCell> 
                                        ))}
                                      
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
                            <DialogTitle>Delete Modules</DialogTitle>
                            <DialogContent>
                                Are you sure you want to delete this Modules?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setEditOpen(false)}>Cancel</Button>
                                <Button onClick={handleDelete} variant="contained">
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Add Department and Section</DialogTitle>
                    <DialogContent>
                        {/* <TextField
                            label="Department"
                            value={newSection.department}
                            onChange={(e) =>
                                setNewSection({
                                    ...newSection,
                                    department: e.target.value,
                                })
                            }
                            fullWidth
                            margin="normal"
                        /> */}
                        <Select
                            fullWidth
                            labelId="department-label"
                            value={newSection.department}
                            onChange={(e) =>
                                setNewSection({
                                    ...newSection,
                                    department: e.target.value,
                                })
                            }
                        >Select Role
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="pedagogy">Teacher</MenuItem>
                            <MenuItem value="Principal">Principal</MenuItem>
                            <MenuItem value="Student">Student</MenuItem>
                        </Select>
                        <TextField
                            label="Section"
                            value={newSection.section}
                            // value={selectedTitle}
                            onChange={(e) =>
                                setNewSection({ ...newSection, section: e.target.value })
                            }
                            fullWidth
                            margin="normal"
                        />
                        <FormLabel component="legend">Permissions</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissionss.read}
                                        onChange={handleChanges}
                                        name="read"
                                        color="primary"
                                    />
                                }
                                label="Read"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissionss.write}
                                        onChange={handleChanges}
                                        name="write"
                                        color="primary"
                                    />
                                }
                                label="Write"
                            />
                            {/* <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissionss.canAccess}
                                        onChange={handleChanges}
                                        name="canAccess"
                                        color="primary"
                                    />
                                }
                                label="Can Access"
                            /> */}
                        </FormGroup>
                    </DialogContent>



                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button onClick={handleAddSection}>Add Section</Button>
                    </DialogActions>
                </Dialog>
            </Box>

        </MainCard>
    );
}
export default ViewModuleTable;
