import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AdmissionFormDyanmic from "./AdmissionFormDyanmic";
import MainCard from "../../_Components/Common/Cards/MainCard";
import { useLocation, useSearchParams } from "react-router-dom";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AdmissionTab() {
  const [value, setValue] = React.useState(0);
  const [searchParams] = useSearchParams();
  const title = searchParams.get("type").replaceAll("_", " ");
  console.log(title);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainCard cardTitle={title} customClass="">
      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Admission Form" {...a11yProps(0)} />
          <Tab label="Get Details By REGISTRATION NO" {...a11yProps(1)} />
          
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        Item One
       
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel> */}

      <AdmissionFormDyanmic />
    </MainCard>
  );
}
export default AdmissionTab;
