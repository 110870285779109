import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";
import { useEffect, useState } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import axios from "axios";

const AttendanceFormatContent = ({
  initialInfo,
  customInfo,
  onFormatChange,
}) => {
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    attendanceLabelKey: [],
    attendanceValueKey: [],
  });

  const nToken = localStorage.getItem("nToken");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [markingState, setMarkingState] = useState(initialInfo?.marking || {});

  useEffect(() => {
    if (initialInfo?.marking) {
      const attendanceLabel = initialInfo.marking.map((data) => data);
      const attendanceValue = Object.values(initialInfo.marking);

      setSelectedOptions({
        attendanceLabelKey: attendanceLabel,
        attendanceValueKey: attendanceValue,
      });
    }
  }, [initialInfo]);

  useEffect(() => {
    if (selectedOptions?.attendanceLabelKey) {
      const initialState = selectedOptions.attendanceLabelKey.reduce(
        (acc, label) => {
          acc[label] = true;
          return acc;
        },
        {}
      );
      setMarkingState(initialState);
    }
  }, [selectedOptions]);
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setMarkingState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleCustomCheckboxChange = (index) => (event) => {
    const updatedSections = sections.map((section, i) =>
      i === index
        ? { ...section, isCustomChecked: event.target.checked }
        : section
    );
    setSections(updatedSections);
  };

  const handleLabelChange = (index) => (event) => {
    const updatedSections = sections.map((section, i) =>
      i === index ? { ...section, customLabel: event.target.value } : section
    );
    setSections(updatedSections);
  };

  const addCustomSection = () => {
    setSections([...sections, { isCustomChecked: false, customLabel: "" }]);
  };

  const removeCustomSection = (index) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSections(updatedSections);
  };
  useEffect(() => {
    const hasEmptyCustomLabel = sections.some(
      (section) => section.isCustomChecked && !section.customLabel.trim()
    );

    setIsSaveDisabled(hasEmptyCustomLabel);
  }, [sections]);

  const handleSaveFormat = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const customSectionData = sections
      .filter((section) => section.isCustomChecked && section.customLabel)
      .map((section) => section.customLabel);

    const invalidCustomLabel = sections.some(
      (section) => section.isCustomChecked && !section.customLabel.trim()
    );

    if (invalidCustomLabel) {
      setError("Please provide valid custom labels for all checked sections.");
      setIsLoading(false);
      return;
    }

    const selectedAttendanceLabels = selectedOptions.attendanceLabelKey.filter(
      (label, index) =>
        markingState[label] !== undefined
          ? markingState[label]
          : selectedOptions.attendanceValueKey[index]
    );

    const finalSelectedValues = [
      ...selectedAttendanceLabels,
      ...customSectionData,
    ];

    const updateDataVal = {
      attendance: {
        marking: finalSelectedValues,
      },
    };

    const presentData = { ...customInfo, ...updateDataVal };
    const datas = JSON.stringify(presentData);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/updateConfigData`,
        {
          config: datas,
          schoolcode: userinfo[0]?.institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      alert("Updated Successfully");
      onFormatChange(response.data.success);
    } catch (error) {
      console.error("Error updating permissions:", error);
      setError("Failed to update the attendance format. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSaveFormat}>
      {console.log(selectedOptions?.attendanceLabelKey, "vvvvvvvvvvvvvvvvvv")}

      {selectedOptions?.attendanceLabelKey?.map((label, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              name={label}
              checked={markingState[label] || false}
              onChange={handleCheckboxChange}
            />
          }
          label={label}
        />
      ))}

      <IconButton
        className="iconBtns"
        aria-label="Add row for other class or subject"
        onClick={addCustomSection}
      >
        <AiOutlinePlusCircle fontSize={26} color="primary" />
      </IconButton>

      <Grid container mt={2}>
        {sections?.map((section, index) => (
          <Grid item xs={12} md={3} key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCustomCheckboxChange(index)}
                  checked={section.isCustomChecked}
                />
              }
            />
            <TextField
              onChange={handleLabelChange(index)}
              value={section.customLabel}
              id="outlined-basic"
              label={`Custom Label ${index + 1}`}
              variant="outlined"
              error={section.isCustomChecked && !section.customLabel.trim()}
              helperText={
                section.isCustomChecked && !section.customLabel.trim()
                  ? "Label is required"
                  : ""
              }
            />
            <IconButton
              className="iconBtns"
              aria-label="Remove row"
              onClick={() => removeCustomSection(index)}
              sx={{ ml: 1 }}
            >
              <AiOutlineMinusCircle fontSize={26} />
            </IconButton>
          </Grid>
        ))}
      </Grid>
      {error && <p style={{ color: "red" }}>{error}</p>}

      <CenterButtonBoxArea mt={2}>
        <PrimaryBgBtn type="submit" disabled={isLoading || isSaveDisabled}>
          {isLoading ? "Saving..." : "Submit"}
        </PrimaryBgBtn>
      </CenterButtonBoxArea>
    </form>
  );
};

export default AttendanceFormatContent;
