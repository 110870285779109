import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";

const TimTblStructure = ({
  classSec,
  onSelectClass,
  onSelectClassVal,
  onSelectSection,
}) => {
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  const handleClassSection = (selectedClass, selectedSection) => {
    const selectedClassSection = selectedClass + selectedSection;
    onSelectSection(selectedSection);
    const selectVal = classSec.filter(
      (data) => data.classsection === selectedClassSection
    );
    onSelectClass(selectVal[0]?.id);
    onSelectClassVal(selectedClass);
  };

  return (
    <Box sx={{ minWidth: "300px" }}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <FormControl size="small" fullWidth>
            <InputLabel>Class:</InputLabel>
            <Select
              onChange={(e) => {
                setSelectedClass(e.target.value);
                handleClassSection(e.target.value, selectedSection);
              }}
              name="class"
              label="Class"
              required
            >
              {Array.from(
                new Set(classSec.map((obj) => obj.class).sort((a, b) => a - b))
              ).map((data) => (
                <MenuItem key={data} value={data}>
                  Class {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl size="small" fullWidth>
            <InputLabel>Section:</InputLabel>

            <Select
              onChange={(e) => {
                setSelectedSection(e.target.value);
                handleClassSection(selectedClass, e.target.value);
              }}
              name="section"
              label="Section"
              required
            >
              {Array.from(
                new Set(
                  classSec.map((obj) => obj.section).sort((a, b) => a - b)
                )
              ).map((data) => {
                return (
                  <MenuItem key={data} value={data}>
                    {data}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimTblStructure;
