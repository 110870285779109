import { Box, Button, Card, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material"
import BackButton from "../../../_Components/Common/BackBtn/BackBtn"
import { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Loader from "../../../_Components/Utils/loader/Loader";

function AddQuestions() {
    const token = localStorage.getItem("token");
    const [SearchParams] = useSearchParams();
    const courseID = SearchParams.get("courseID");
    const quizID = SearchParams.get("quizID");
    const questionID = SearchParams.get("questionID");
    const catID = SearchParams.get("catID");
    const categoryID = SearchParams.get("categoryID");
    const editing = SearchParams.get("editing");
    const moduleID = SearchParams.get("moduleID");
    const userId = JSON.parse(localStorage.getItem("userInfo"))[0].id;
    const [questionAdded, setQuestionAdded] = useState({ message: "", status: "", isAdded: false });
    const [questionType, setQuestionType] = useState("multichoice");






    console.log(courseID);


    const defaultMultiChoiceData = {
        "url": `${process.env.REACT_APP_wsPrefix}/`,
        "token": token,
        "qtype": "multichoice",
        "courseid": parseInt(courseID),

        "name": "Testing question1111",
        "parent": 0,
        "length": 1,
        "questiontext": "",
        "generalfeedback": "Test genral feedbacktext first1111",
        "answernumbering": "abc",
        "correctfeedback": "Your answer is correct",
        "partiallycorrectfeedback": "Your answer is partially correct.",
        "incorrectfeedback": "Your answer is incorrect.",
        "answer": ["", ""],
        "fraction": ["1.0", "0.0"],
        "answerfeedback": ["first", "second"],
        "createdby": parseInt(userId),
        "maxgrade": 2,
        "quizid": quizID,
        "category": catID
    }

    const defaultMultiAnswerData = {
        "url": `${process.env.REACT_APP_wsPrefix}/`,
        "qtype": "multianswer",
        "courseid": parseInt(courseID),
        "category": catID,
        "quizid": quizID,
        "length": 1,
        "name": "",
        "parent": 0,
        "questiontext": "",
        "questionoption": ["", ""],
        "questionanswers": ["", ""],
        "generalfeedback": "Test q1 General feedback",
        "usecase": 0,
        "createdby": parseInt(userId)
    }



    const defaultTrueFlaseData = {
        "url": `${process.env.REACT_APP_wsPrefix}/`,
        "token": token,
        "qtype": "truefalse",
        "courseid": parseInt(courseID),
        "category": catID,
        "quizid": quizID,
        "length": 1,
        "name": "Test TF Question",
        "parent": 0,
        "questiontext": "",
        "generalfeedback": "Test q1 General feedback",
        "feedbacktrue": "Feedback for the response 'True'.",
        "feedbackfalse": "Feedback for the response 'False'.",
        "correctanswer": 1,
        "createdby": parseInt(userId),
        "maxgrade": 2,
    }
    const multichoiceOptions = ["option0", "option1"];
    const [isAddingQuestion, setIsAddingQuestion] = useState(false);
    const [multiChoiceQuestionData, setMultiChoiceQuestionData] = useState(defaultMultiChoiceData);
    const [multiAnswerQuestionData, setMultiAnswerQuestionData] = useState(defaultMultiAnswerData);
    const [trueFlaseQuestionData, setTrueFalseQuestionData] = useState(defaultTrueFlaseData);
    const [questOptions, setQuestOptions] = useState(multichoiceOptions);


    const getQuestionData = () => {
        const getQuestionDataURL = `${process.env.REACT_APP_nodewsPrefix}/questionview?questionID=${questionID}`
        axios.get(getQuestionDataURL, {
            headers: {
                Authorization: `Bearer ${nToken}`,
            },
        }).then(response => {
            console.log(response.data.questionData.qtype, "ind124");
            setQuestionType(response.data.questionData.qtype);


            if (response.data.questionData.qtype === "multichoice") {
                let answerOption = response.data.answerData.map(answer => answer.answer);
                let correctAnswer = response.data.answerData.map(data => data.fraction);
                let optionsUpdate = response.data.answerData.map((data, indx) => `option${indx}`);
                setQuestOptions(optionsUpdate);
                setMultiChoiceQuestionData(prevState => {
                    return {
                        ...prevState,
                        name: response.data.questionData.questionName,
                        questiontext: response.data.questionData.questiontext,
                        answer: answerOption,
                        fraction: correctAnswer
                    }
                })
            }

            if (response.data.questionData.qtype === "truefalse") {

                setTrueFalseQuestionData(prevState => {
                    return {
                        ...prevState,
                        name: response.data.questionData.questionName,
                        questiontext: response.data.questionData.questiontext,
                    }
                })
            }
        }).catch(error => {
            setQuestionAdded({ message: "Some problem please try again", status: "error", isAdded: false });
            console.log(error);
        })
    }

    useEffect(() => {
        if (editing === "true") {
            getQuestionData();
        }
    }, [editing]);



    const nToken = localStorage.getItem("nToken");
    const addQuestionURL = `${process.env.REACT_APP_nodewsPrefix}/createQuestion`;
    const handleQuestionType = e => {
        setQuestionType(e.target.value)
    }

    const addQuestionHandler = (e, questionData) => {
        console.log(questionData, "addQuestionHandler")
        e.preventDefault();
        setIsAddingQuestion(true)
        if (editing === "true") {
            console.log(questionData, "SSSS33");
            let editData = { ...questionData, category: categoryID, quizid: moduleID, qid: questionID }
            axios.post(addQuestionURL, editData, {
                headers: {
                    Authorization: `Bearer ${nToken}`,
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                setIsAddingQuestion(false);
                console.log(response.data);
                setIsAddingQuestion(false);
                setQuestionAdded({ message: "Question edited successfully", status: "success", isAdded: true });
                setTimeout(() => {
                    setQuestionAdded({ message: "", status: "", isAdded: false })
                }, 5000)
            }).catch(err => {
                console.log(err);
            })
        }
        else {

            console.log(questionData, "innnder");
            questionData.name = questionData.questiontext;

            axios.post(addQuestionURL,
                questionData, {
                headers: {
                    Authorization: `Bearer ${nToken}`,
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                console.log(response.data, "ind1234");
                setIsAddingQuestion(false);
                setMultiChoiceQuestionData(defaultMultiChoiceData)
                setTrueFalseQuestionData(defaultTrueFlaseData)
                setQuestionAdded({ message: "Question added successfully", status: "success", isAdded: true });
                setTimeout(() => {
                    setQuestionAdded({ message: "", status: "", isAdded: false })
                }, 5000)
            }).catch(err => {
                console.log(err);
                setIsAddingQuestion(false);
                setQuestionAdded({ message: "Some problem question not added", status: "error", isAdded: false });
                setTimeout(() => {
                    setQuestionAdded({ message: "", status: "", isAdded: false })
                }, 5000)
            })
        }


    }

    const handleQuestionCreateInputChange = (e, qType) => {

        console.log(qType, e.target.name, e.target.value)
        if (qType === "multianswer") {
            setMultiAnswerQuestionData(prevState => {
                return { ...prevState, [e.target.name]: e.target.value }
            });
        }

        if (qType === "multichoice") {

            setMultiChoiceQuestionData(prevState => {
                console.log(prevState)
                return { ...prevState, [e.target.name]: e.target.value }
            })
        }

        if (qType === "truefalse") {
            setTrueFalseQuestionData(prevState => {
                return { ...prevState, [e.target.name]: e.target.value, "name": e.target.value }
            })
        }
    }

    const handleQuestionCreateOptionChange = (e, qType) => {

        if (qType === "multichoice") {

            if (e.target.name.includes("option")) {

                console.log((e.target.name).split("option")[1], e.target.value);
                let optionNumber = (e.target.name).split("option")[1]

                setMultiChoiceQuestionData(prevState => {
                    console.log(questOptions, "qopt")
                    let prevAnswer = prevState.answer;
                    prevAnswer[optionNumber] = e.target.value;
                    let prevFraction = questOptions.map(options => "0.0");
                    let answerfeed = questOptions.map(feedback => "OK");
                    prevFraction[e.target.value] = "1.0";
                    return {
                        ...prevState,
                        answer: prevAnswer,
                        fraction: prevFraction,
                        answerfeedback: answerfeed
                    }
                })
            } else {
                setMultiChoiceQuestionData(prevState => {
                    let answerfeed = questOptions.map(feedback => "OK");
                    console.log(answerfeed)
                    let prevFraction = questOptions.map(options => "0.0");
                    prevFraction[e.target.value] = "1.0";
                    return {
                        ...prevState,
                        fraction: prevFraction,
                        answerfeedback: answerfeed
                    }
                })
            }

        }
    }

    const addRemoveOptions = (edit) => {
        console.log(edit, questOptions)
        if (edit === "add") {
            //console.log(edit,indx)
            setQuestOptions(prevState => { return [...prevState, `option${questOptions.length}`] })
        } else {
            setQuestOptions(prevState => { let arr = prevState.toSpliced(-1); return arr })
        }
    }

    return (
        <div className="dashboardMain">
            {console.log(trueFlaseQuestionData)}
            <Container maxWidth="xl">
                <Grid item md={12} sm={12}>
                    <Card
                        maxWidth="xl"
                        sx={{
                            mb: 4,
                            backgroundColor: "cardColor.main",
                            color: "cardColor.contrast",
                        }}
                        elevation={5}
                        className="customCard"
                    >
                        <Typography
                            className="title"
                            variant="h5"
                            px={2}
                            py={1}
                            sx={{ borderColor: "borderLine.main" }}
                        >
                            <Box className="titleHead">
                                <BackButton />
                                <Box>
                                    {editing === "true" ? "Edit Question" : "Add Question"}
                                </Box>
                            </Box>
                        </Typography>

                        <Box className="customCardContent" p={2}>
                            <Box className="quizForm">
                                {console.log(questionType, "ind125")}

                                <FormControl>
                                    <FormLabel id="questionType">Question Type</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="questionType"
                                        value={questionType}
                                        name="qtype"
                                        onChange={handleQuestionType}
                                        row
                                    >
                                        {/* <FormControlLabel value="multianswer" control={<Radio />} label="Short Answer" disabled={isAddingQuestion || (editing === "true" ? true : false)} /> */}
                                        <FormControlLabel value="multichoice" control={<Radio />} label="MCQ" disabled={isAddingQuestion || (editing === "true" ? true : false)} />
                                        <FormControlLabel value="truefalse" control={<Radio />} label="True false" disabled={isAddingQuestion || (editing === "true" ? true : false)} />
                                    </RadioGroup>
                                </FormControl>

                                {console.log(questionType)}
                                {
                                    questionType === "multichoice" &&
                                    < form onSubmit={(e) => { addQuestionHandler(e, multiChoiceQuestionData) }}>
                                        <TextField
                                            label="Question Text"
                                            name="questiontext"
                                            className="inputField"
                                            type="text"
                                            required
                                            value={multiChoiceQuestionData.questiontext}
                                            autoFocus={true}
                                            sx={{ width: "100%", mt: 3 }}
                                            onChange={(e) => handleQuestionCreateInputChange(e, questionType)}
                                            disabled={isAddingQuestion}
                                        />
                                        {console.log(questOptions)}
                                        <Grid container className="options">
                                            {
                                                questOptions.map((optionName, indx) => {
                                                    return (
                                                        <>
                                                            <Grid key={indx} item md={6} xs={12} className="fieldWrapper">
                                                                <TextField
                                                                    name={optionName}
                                                                    required
                                                                    label={`Option ${indx + 1}`}
                                                                    className="inputField"
                                                                    onChange={e => handleQuestionCreateOptionChange(e, questionType)}
                                                                    value={multiChoiceQuestionData.answer[indx]}
                                                                    disabled={isAddingQuestion}
                                                                />
                                                                <Box>
                                                                    {(questOptions.length - 1) === indx ? indx > 1 ? <><RemoveCircleOutlineIcon onClick={() => { addRemoveOptions("remove", indx) }} /><AddCircleOutlineIcon onClick={() => { addRemoveOptions("add") }} /></> : "" : ""}

                                                                    {(questOptions.length - 1) === indx && indx < 2 && <AddCircleOutlineIcon onClick={() => { addRemoveOptions("add", indx) }} />}
                                                                </Box>
                                                            </Grid>

                                                        </>
                                                    )
                                                })
                                            }
                                            {
                                                <FormControl fullWidth sx={{ mt: 3 }}>
                                                    <InputLabel id="selectCorrectAnswer">Select Correct Answer</InputLabel>
                                                    <Select
                                                        labelId="selectCorrectAnswer"
                                                        id="selectCorrectAnswer"
                                                        name="selectCorrectAnswer"
                                                        input={<OutlinedInput label="Select Correct Answer" />}
                                                        defaultValue={0}
                                                        label="Age"
                                                        onChange={(e) => handleQuestionCreateOptionChange(e, questionType)}
                                                        disabled={isAddingQuestion}
                                                    >
                                                        {console.log(questOptions)}
                                                        {
                                                            questOptions.map((optionName, indx) => {
                                                                return (
                                                                    <MenuItem key={indx} value={indx}>Option {indx + 1}</MenuItem>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </FormControl>
                                            }
                                        </Grid>
                                        {isAddingQuestion && <Loader />}
                                        {console.log(questionAdded, "RSPL 111")}
                                        <Box sx={{ mt: 3 }} className={`quizMessage ${questionAdded.status}`}>{questionAdded.message}</Box>
                                        <Box sx={{ mt: 3 }}><Button type="submit" variant="contained">{editing === "true" ? "Edit Question" : "Add Question"}</Button></Box>
                                    </form>

                                }



                                {
                                    questionType === "truefalse" &&

                                    <form onSubmit={(e) => { addQuestionHandler(e, trueFlaseQuestionData) }}>
                                        <TextField
                                            label="Question Text"
                                            name="questiontext"
                                            className="inputField"
                                            type="text"
                                            required
                                            value={trueFlaseQuestionData.questiontext}
                                            autoFocus={true}
                                            disabled={isAddingQuestion}
                                            sx={{ width: "100%", mt: 3 }}
                                            onChange={(e) => handleQuestionCreateInputChange(e, questionType)}
                                        />

                                        <FormControl sx={{ mt: 3 }}>
                                            <FormLabel id="correctAnswer">Correct Answer</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="correctAnswer"
                                                defaultValue={1}
                                                name="correctanswer"
                                                onChange={(e) => handleQuestionCreateInputChange(e, questionType)}
                                                row
                                                disabled={isAddingQuestion}
                                            >
                                                <FormControlLabel value={1} control={<Radio />} label="True" disabled={isAddingQuestion} />
                                                <FormControlLabel value={0} control={<Radio />} label="False" disabled={isAddingQuestion} />
                                            </RadioGroup>
                                        </FormControl>
                                        {console.log(editing, "ind323232")}
                                        {isAddingQuestion && <Loader />}
                                        <Box sx={{ mt: 3 }} className={`quizMessage ${questionAdded.status}`}>{questionAdded.message}</Box>
                                        <Box sx={{ mt: 3 }}><Button type="submit" variant="contained" disabled={isAddingQuestion}>{editing === "true" ? "Edit Question" : "Add Question"}</Button></Box>
                                    </form>

                                }

                                {
                                    questionType === "multianswer" &&
                                    <form onSubmit={(e) => { addQuestionHandler(e, multiAnswerQuestionData) }}>
                                        <TextField
                                            label="Question Text"
                                            name="questiontext"
                                            className="inputField"
                                            type="text"
                                            required
                                            value={multiAnswerQuestionData.questiontext}
                                            autoFocus={true}
                                            disabled={isAddingQuestion}
                                            sx={{ width: "100%", mt: 3 }}
                                            onChange={(e) => handleQuestionCreateInputChange(e, questionType)}
                                        />
                                        <Grid container className="options">
                                            {multiAnswerQuestionData.questionanswers.map((answer, indx) => {
                                                return (
                                                    <Grid key={indx} item md={6} xs={12} className="fieldWrapper">
                                                        <TextField
                                                            name={answer}
                                                            required
                                                            label={`Option ${indx + 1}`}
                                                            className="inputField"
                                                            onChange={e => handleQuestionCreateOptionChange(e, questionType)}
                                                            value={multiAnswerQuestionData.questionanswers[indx]}
                                                            disabled={isAddingQuestion}
                                                        />
                                                        <Box>
                                                            {(multiAnswerQuestionData.questionanswers.length - 1) === indx ? (indx > 0 ?<><RemoveCircleOutlineIcon onClick={() => { addRemoveOptions("remove", indx) }} /><AddCircleOutlineIcon onClick={() => { addRemoveOptions("add") }} /></> :"4") : "2"}
                                                        </Box>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </form>

                                }



                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Container>
        </div >
    )
}

export default AddQuestions
