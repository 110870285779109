import { Box, Button, Card, List, ListItem, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { languages } from "../../App";
import { useContext, useEffect, useState } from "react";
import axios from "axios";

function QuizSummaryList(props) {
  const token = localStorage.getItem("token");
  let curLanguage = useContext(languages);
  const attemptSummaryURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_attempt_summary&moodlewsrestformat=json&attemptid=${props.attId}`;

  const [attemptSummary, SetattemptSummary] = useState();

  useEffect(() => {
    axios.get(attemptSummaryURL).then((response) => {
      console.log("checking1.0: list response", response.data)
      SetattemptSummary(response.data);
    });
  }, [attemptSummaryURL]);

  return (
    <>
      <List className="attemptUl">
        {attemptSummary?.questions.map((values) => (
          <ListItem key={values.slot} className="attemptLi">
            <NavLink
              to={`/assessmentAttempt?id=${props.attId}&pageNo=${values.slot
              }`}
              state={{
                page: values.page,
                attemptid: props.attId,
                quizSummarycheck: "yes",
              }}
            >
              <Card
                sx={{
                  backgroundColor: "cardColor.main",
                  color: "cardColor.contrast",
                }}
                elevation={5}
                className="attemptLiCard"
              >
                <Typography>{values.slot}</Typography>
                <Typography>{values.status}</Typography>
              </Card>
            </NavLink>
          </ListItem>
        ))}
      </List>

      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <Button
          sx={"btn"}
          color="buttons"
          variant="contained"
          size="small"
          onClick={props.sumbitHandler}
        >
          {curLanguage.submit}
        </Button>
      </Box>
    </>
  );
}

export default QuizSummaryList;
