import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { languages } from "../../App";
import axios from "axios";
import FeeInfo from "./FeeInfo";
import MainCard from "../../_Components/Common/Cards/MainCard";
import {
  CenterButtonBoxArea,
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

function FeeCollection() {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");
  let curLanguage = useContext(languages);
  const [feeData, setFeeData] = useState([]);
  const [feeStatus, setFeeStatus] = useState();
  const [stdClassInfo, setStdClassInfo] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState({
    admission_no: "",
    student_name: "",
    class: "",
    section: "",
    father_name: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        const data = response.data.class_section;
        setStdClassInfo(data);
        const uniqueClasses = [...new Set(data.map((item) => item.class))];
        setClasses(uniqueClasses);
        const uniqueSections = [...new Set(data.map((item) => item.section))];
        setSections(uniqueSections);
      } catch (error) {
        console.error("Error fetching class and section data:", error);
      }
    };

    fetchData();
  }, []);

  const handleInput = (event) => {
    const { name, value, type } = event.target;
    if (type === "text") {
      if (/^[A-Za-z ]+$/.test(value) || value === "") {
        setSearchCriteria((prevCriteria) => ({
          ...prevCriteria,
          [name]: value,
        }));
      }
    } else {
      setSearchCriteria((prevCriteria) => ({
        ...prevCriteria,
        [name]: value,
      }));
    }
  };

  const feeInfo = (event) => {
    event.preventDefault();
    const feesInfo = `${process.env.REACT_APP_nodewsPrefix}/getFeeData`;
    axios
      .post(
        feesInfo,
        {
          ...searchCriteria,
          schoolcode: userinfo[0].institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      )
      .then((response) => {
        setFeeData(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setFeeStatus(feeData);
  };

  const isEmptySearchCriteria = () => {
    const { schoolcode, ...otherCriteria } = searchCriteria;
    return Object.values(otherCriteria).every((value) => value === "");
  };

  const onRefresh = () => {
    setSearchCriteria({
      admission_no: "",
      student_name: "",
      class: "",
      section: "",
      father_name: "",
    });
    setFeeStatus("");
  };

  return (
    <MainCard cardTitle="Fee">
      <Box mt={2} component="form" onSubmit={feeInfo} noValidate>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item md={2.5}>
            <TextField
              name="admission_no"
              type="number"
              id="outlined-required"
              label={curLanguage.admissionno}
              sx={{ width: "100%" }}
              onChange={handleInput}
              value={searchCriteria.admission_no}
            />
          </Grid>
          <Grid item md={0.5}>
            <Typography>OR</Typography>
          </Grid>
          <Grid item md={1.5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="class-label"> {curLanguage.class}</InputLabel>
              <Select
                labelId="class-label"
                id="class"
                name="class"
                value={searchCriteria.class}
                onChange={handleInput}
                label="Class"
              >
                {classes.map((cls) => (
                  <MenuItem key={cls} value={cls}>
                    {cls}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1.5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="section-label">{curLanguage.section}</InputLabel>
              <Select
                labelId="section-label"
                id="section"
                name="section"
                value={
                  searchCriteria.section === "All"
                    ? "All Section"
                    : searchCriteria.section
                }
                onChange={handleInput}
                label="Section"
              >
                <MenuItem value={sections}>All Sections</MenuItem>
                {sections.map((section) => (
                  <MenuItem key={section} value={section}>
                    {section}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={3}>
            <TextField
              name="student_name"
              id="outlined-required"
              label={curLanguage.studentname}
              sx={{ width: "100%" }}
              onChange={handleInput}
              value={searchCriteria.student_name}
              type="text"
            />
          </Grid>

          <Grid item md={3}>
            <TextField
              name="father_name"
              id="outlined-required"
              label={curLanguage.fathername}
              sx={{ width: "100%" }}
              onChange={handleInput}
              value={searchCriteria.father_name}
              type="text"
            />
          </Grid>
        </Grid>
        <CenterButtonBoxArea sx={{ mt: 2 }}>
          <PrimaryOutlineBtn onClick={onRefresh}  disabled={isEmptySearchCriteria()}>Reset</PrimaryOutlineBtn>
          <PrimaryBgBtn type="submit" disabled={isEmptySearchCriteria()}>
            {curLanguage.search}
          </PrimaryBgBtn>
        </CenterButtonBoxArea>
      </Box>
      {feeStatus && <FeeInfo data={feeData} curLanguage={curLanguage} />}
    </MainCard>
  );
}
export default FeeCollection;
