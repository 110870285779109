import { useEffect, useState } from "react";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import axios from "axios";
import AttendanceFormatContent from "./AttendanceFormatContent";

const AttendanceFormat = () => {
  const [initialInfo, setInitialInfo] = useState();
  const [customInfo, setCustomInfo] = useState();
  const [updateinitialInfo, setUpdateinitialInfo] = useState();
  const [formatUpdate, setFormatUpdate] = useState(false);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept`,
          {
            schoolcode: userinfo[0]?.institution,
            department: userinfo[0]?.department,
          }
        );
        const updatedData = response.data.config.attendance;
        setInitialInfo(
          updatedData ? updatedData : response.data.defaultConfig.attendance
        );
        setCustomInfo(response.data.config);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchData();
  }, [formatUpdate]);

  useEffect(() => {
    if (initialInfo) {
      setUpdateinitialInfo(initialInfo);
    }
  }, [initialInfo, formatUpdate]);

  const handleFormatChange = (data) => {
    setFormatUpdate(data);
    console.log("attendance format changed", data)
  };

  return (
    <MainCard cardTitle="Attendance Format">
      <AttendanceFormatContent
        initialInfo={updateinitialInfo}
        customInfo={customInfo}
        onFormatChange={handleFormatChange}
      />
    </MainCard>
  );
};

export default AttendanceFormat;
